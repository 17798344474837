import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBTestimonial } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Card from '../components/card'
import ColumnAlternating from '../components/columnAlternatingStyled'

const IBM = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.gatsbyImageData.images .fallback.src }
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.openingpara,
                    }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => {
                    return (
                      <ColumnAlternating
                        key={index}
                        textCol={'7'}
                        imageCol={'5'}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                        description={sections.description}
                        image={sections.image.childImageSharp.gatsbyImageData}
                        alt={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                        link={sections.btnlink}
                        colour={sections.btncolour}
                      />
                    )
                  })}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        {post.frontmatter.quotes && (
          <section className="blockback">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBCarousel activeItem={1} length={post.frontmatter.quotes.length} testimonial showControls={false} showIndicators={true} className="no-flex" interval={10000}>
                <MDBCarouselInner>
                  {post.frontmatter.quotes.map((quote, index) => {
                    return (
                      <MDBCarouselItem itemId={index + 1} key={index}>
                        <MDBTestimonial>
                          <div className="blockquote-wrapper">
                            <div className="blockquote">
                              <h2>{quote.text}</h2>
                              {quote.name && (
                                <p style={{ whiteSpace: 'pre-wrap' }}> {quote.name} </p>
                              )}
                            </div>
                          </div>
                        </MDBTestimonial>
                      </MDBCarouselItem>
                    )
                  })}
                </MDBCarouselInner>
              </MDBCarousel>
            </MDBAnimation>
          </section>
        )}

        {post.frontmatter.helpfulresources && (
          <section className="bg-gray-light">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {post.frontmatter.helpfulresources.title}
                </h2>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map( (helpfulres, index) => {
                          return (
                            <Card
                              key={index}
                              collg="4"
                              colmd="6"
                              height="13.5rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={ helpfulres.image.childImageSharp.gatsbyImageData }
                              alt={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={helpfulres.link}
                              descriptionClass="text-card-small"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

      </main>
    </Layout>
  )
}
export default IBM

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { name: { eq: "UNICOM Global and IBM zSystems" } }) {
      frontmatter {
        meta {
          title
          description
        }
        name
        title
        subtitle
        alttext
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        section {
          title
          subtitle
          description
          image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          placement
          alttext
          btnlink
          btncolour
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            linktext
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        quotes {
          text
          name
        }
      }
      html
    }
  }
`